<template>
  <div class="main-wrapper">
    <!-- Page Section Start -->
    <div class="page-section section section-padding section-space--inner--60">
      <div class="container">
        <div class="row">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home ></a></li>
              <li class="breadcrumb-item">
                <a
                  :href="
                    /product-category/ + this.$route.params.kategori_produk
                  "
                >
                  {{ this.$route.params.kategori_produk }} ></a
                >
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ this.$route.params.merk_produk }}
              </li>
            </ol>
          </nav>
        </div>
        <div class="row row-30 mb-40">
          <div
            class="col-xl-9 col-lg-8 col-12 order-1 order-lg-2"
            style="margin-bottom: 40px"
          >
            <div class="row justify-content-end" style="margin-bottom: 40px">
              <div class="col-12 col-md-12 thumbnail-space">
                <h3>
                  {{ this.$route.params.kategori_produk }}
                  {{ this.$route.params.merk_produk }}
                </h3>
                <p>Menampilkan {{ show.total }} produk</p>
              </div>
              <!-- <div class="col-12 col-md-2">
                <div class="input-group">
                  <input
                    type="text"
                    v-model="search"
                    class="form-control"
                    placeholder="Cari barang disini ..."
                    @keyup.enter="handleSearch"
                  />
                  <div class="input-group-prepend">
                    <button class="btn btn-primary">
                      <a :href="/search/ + this.search">
                        <i class="fa fa-search"></i
                      ></a>
                    </button>
                  </div>
                </div>
              </div> -->

              <!-- <div class="col-12 col-md-5">
                <div class="input-group">
                  <input
                    type="text"
                    v-model="search"
                    class="form-control"
                    placeholder="Cari barang disini ..."
                  />
                  <div class="input-group-prepend">
                    <button class="btn btn-primary">
                      <i class="fa fa-search"></i>
                    </button>
                  </div>
                </div>

                <div
                  class="thumbnail-card"
                  v-if="showsearch == false && this.search.length > 0"
                >
                  <div
                    class="card list-thumbnail mb-2"
                    v-for="produk in filteredProduks.slice(0, 3)"
                    :key="produk.id_produk"
                  >
                    <SearchList :produk="produk" />
                  </div>
                  <div class="row more-search">
                    <a :href="/search/ + this.search">Lihat lebih banyak </a>
                  </div>
                </div>
              </div> -->
            </div>
            <!-- SHOW DATA -->

            <!-- show all data -->
            <div class="row">
              <div
                v-for="produk in produks"
                :key="produk.id_produk"
                class="col-xl-3 col-md-4 col-6 product-card"
              >
                <router-link
                  :to="
                    /detil-product/ +
                    produk.id_produk +
                    /jenis-produk/ +
                    produk.jenis_produk +
                    /merk-produk/ +
                    produk.merk_produk +
                    /nama-produk/ +
                    produk.nama_produk
                  "
                >
                  <div class="card">
                    <img
                      class="card-img-top"
                      :src="produk.url_gambar_produk"
                      :alt="produk.nama_produk"
                    />
                    <div class="card-body"> 
                      <h5 class="card-title">
                        <div v-if="produk.nama_produk.length < 40">
                          <p class="title">{{ produk.nama_produk }}</p>
                        </div>
                        <div v-else>
                          <p class="title">
                            {{ produk.nama_produk.substring(0, 35) + "..." }}
                          </p>
                        </div>
                      </h5>

                      <h1>{{ produk.total }}</h1>

                      <p class="p-price">
                        <!-- {{ formatPrice(hargamin.harga_produk) }} -
                          {{ formatPrice(hargamax.harga_produk) }} -->
                        Harga di Detail Produk
                      </p>

                      <p class="p-merk">
                        {{ produk.jenis_produk }} {{ produk.merk_produk }}
                      </p>
                    </div>
                  </div></router-link
                >

                <!-- <div class="product-item">
                    <div class="product-inner">
                      <div class="image">
                        <img
                          :src="produk.url_gambar_produk"
                          alt="Image Not Found"
                        />

                        <div class="image-overlay">
                          <div class="action-buttons">
                            <router-link
                              :to="
                                /detil-product/ +
                                produk.id_produk +
                                /jenis-produk/ +
                                produk.jenis_produk +
                                /merk-produk/ +
                                produk.merk_produk +
                                /nama-produk/ +
                                produk.nama_produk
                              "
                            >
                              <a href="">
                                <button>Lihat Produk</button>
                              </a></router-link
                            >
                          </div>
                        </div>
                      </div>

                      <div class="content">
                        <div class="content-left">
                          <div v-if="produk.nama_produk.length < 40">
                            <p class="title">{{ produk.nama_produk }}</p>
                          </div>
                          <div v-else>
                            <p class="title">
                              {{ produk.nama_produk.substring(0, 35) + "..." }}
                            </p>
                          </div>

                          <p>{{ produk.merk_produk }}</p>

                          <h5 class="jenis">
                            Jenis: {{ produk.jenis_produk }}
                          </h5>
                          <h5 class="tipe">Harga Cek Detail Produk</h5>
                        </div>
                      </div>
                    </div>
                  </div> -->
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-lg-4 col-12 order-2 order-lg-1">
            <div class="sidebar">
              <a href="">
                <h4 class="sidebar-title">
                  {{ this.$route.params.kategori_produk }}
                </h4>
              </a>

              <ul class="sidebar-list">
                <li v-for="kategori in kategoris" :key="kategori.id_kategori">
                  <a
                    :href="
                      /product/ +
                      kategori.kategori_produk +
                      ' ' +
                      kategori.tipe_produk +
                      /merk-produk/ +
                      kategori.merk_produk
                    "
                    >{{ kategori.merk_produk }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          v-if="produks.length"
          v-observe-visibility="handleScrolledToBottom"
        ></div>
      </div>
    </div>
    <!-- Page Section End -->
  </div>
</template>

<script>
import axios from "axios";
import SearchList from "@/components/SearchList";
export default {
  components: {
    SearchList,
  },
  data() {
    return {
      page: 1,
      show: [],
      produks: [],
      allproduks: [],
      kategoris: [],
      hargamax: [],
      hargamin: [],
      search: "",
      filter: "",
      showsearch: false,
      showfilter: false,
      caris: [],
    };
  },

  metaInfo() {
    return {
      title:
        "CV. Putra Ramayana Grosir Ban, Oli, Aki, dan Sparepart Solo Murah " +
        "- List Produk " +
        this.$route.params.merk_produk,
      meta: [
        {
          name: "keywords",
          content:
            "Putra Ramayana, CV. Putra Ramayana, CV Putra Ramayana Solo, Grosir Ban, Grosir Sparepart, Grosir Oli, Grosir Aki, Surakarta, Solo",
        },
        {
          name: "description",
          content:
            "CV Putra Ramayana adalah Pusat Grosir Ban, Oli, Aki, dan Sparepart Terjangkau, Terlengkap, dan Terpercaya di Surakarta",
        },
        {
          property: "og:keyword",
          content:
            "Putra Ramayana, CV. Putra Ramayana, CV Putra Ramayana Solo, Grosir Ban, Grosir Sparepart, Grosir Oli, Grosir Aki, Surakarta, SoloPutra Ramayana, CV. Putra Ramayana, CV Putra Ramayana Solo, Grosir Ban, Grosir Sparepart, Grosir Oli, Grosir Aki, Surakarta, Solo",
        },
        {
          name: "og:description",
          content:
            "CV Putra Ramayana Pusat Grosir Ban, Oli, Aki, dan Sparepart Solo Terlengkap dan Termurah - " +
            this.$route.params.merk_produk,
        },
        {
          property: "og:title",
          content:
            this.$route.params.kategori_produk +
            " " +
            this.$route.params.merk_produk +
            " - CV Putra Ramayana " +
            "Grosir Ban Oli Aki Sparepart Solo Murah",
        },
        {
          property: "og:site_name",
          content: "www.putraramayanasolo.com" + this.$route.fullPath,
        },
        {
          property: "og:image",
          content:
            "https://firebasestorage.googleapis.com/v0/b/ramayana-product.appspot.com/o/Logo%2FLogo%20PR.jpg?alt=media&token=dca7045a-28c2-4b2e-8e23-219adf00c977",
        },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    };
  },

  methods: {
    handleSearch() {
      this.$router.push("/search/" + this.search);
    },

    handleScrolledToBottom(isVisible) {
      if (!isVisible) {
        return;
      }
      this.page++;
      this.getData();
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    searchProduct() {
      axios
        .get("https://admin.putraramayana.com/api/produks/getbynama/" + this.search)
        .then((response) => {
          this.caris = response.data;
          this.search = "";
          this.showsearch = true;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    getAllData() {
      axios
        .get("https://admin.putraramayana.com/api/produks")
        .then((response) => {
          this.allproduks = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    // getData() {
    //   axios
    //     .get(
    //       "https://admin.putraramayana.com/api/produks/getbymerk/" +
    //         this.$route.params.merk_produk +
    //         "?page=" +
    //         this.page
    //     )
    //     .then((response) => {
    //       this.produks = response.data.data;
    //     })
    //     .catch((e) => {
    //       this.errors.push(e);
    //     });
    // },

    getPageData() {
      axios
        .get(
          "https://admin.putraramayana.com/api/produks/getbymerk/" +
            this.$route.params.merk_produk
        )
        .then((response) => {
          this.show = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    async getData() {
      let produks = await axios.get(
        "https://admin.putraramayana.com/api/produks/getbymerk/" + this.$route.params.kategori_produk + "/" +this.$route.params.merk_produk +
          "?page=" +
          this.page
      );
      this.produks.push(...produks.data.data);
    },

    getDataKategori() {
      axios
        .get(
          "https://admin.putraramayana.com/api/product-category/" +
            this.$route.params.kategori_produk
        )
        .then((response) => {
          this.kategoris = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },

  computed: {
    filteredProduks: function () {
      var self = this;
      return this.allproduks.filter(function (allproduks) {
        return (
          allproduks.nama_produk
            .toLowerCase()
            .indexOf(self.search.toLowerCase()) >= 0 ||
          allproduks.merk_produk
            .toLowerCase()
            .indexOf(self.search.toLowerCase()) >= 0
        );
      });
    },
  },

  mounted() {
    this.getAllData();
    this.getData();
    this.getPageData();
    this.getDataKategori();
  },
};
</script>

<style></style>
