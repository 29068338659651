<template>
  <div class="home">
    <Header />
    <Product />
    <Footer />
    <OffCanvasMobileMenu />

    <!-- <a class="float-menu-filter">Kategori <i class="fa fa-filter"></i> </a> -->
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header";
import Product from "../components/Product.vue";
import Footer from "../components/Footer";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";

export default {
  name: "App",
  components: {
    Header,
    Product,
    Footer,
    OffCanvasMobileMenu,
  },
  metaInfo: {
    title: "CV.Putra Ramayana - List Produk",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
};
</script>

<style></style>
