var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-wrapper"},[_c('div',{staticClass:"page-section section section-padding section-space--inner--60"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_vm._m(0),_c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":/product-category/ + this.$route.params.kategori_produk}},[_vm._v(" "+_vm._s(this.$route.params.kategori_produk)+" >")])]),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v(" "+_vm._s(this.$route.params.merk_produk)+" ")])])])]),_c('div',{staticClass:"row row-30 mb-40"},[_c('div',{staticClass:"col-xl-9 col-lg-8 col-12 order-1 order-lg-2",staticStyle:{"margin-bottom":"40px"}},[_c('div',{staticClass:"row justify-content-end",staticStyle:{"margin-bottom":"40px"}},[_c('div',{staticClass:"col-12 col-md-12 thumbnail-space"},[_c('h3',[_vm._v(" "+_vm._s(this.$route.params.kategori_produk)+" "+_vm._s(this.$route.params.merk_produk)+" ")]),_c('p',[_vm._v("Menampilkan "+_vm._s(_vm.show.total)+" produk")])])]),_c('div',{staticClass:"row"},_vm._l((_vm.produks),function(produk){return _c('div',{key:produk.id_produk,staticClass:"col-xl-3 col-md-4 col-6 product-card"},[_c('router-link',{attrs:{"to":/detil-product/ +
                  produk.id_produk +
                  /jenis-produk/ +
                  produk.jenis_produk +
                  /merk-produk/ +
                  produk.merk_produk +
                  /nama-produk/ +
                  produk.nama_produk}},[_c('div',{staticClass:"card"},[_c('img',{staticClass:"card-img-top",attrs:{"src":produk.url_gambar_produk,"alt":produk.nama_produk}}),_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title"},[(produk.nama_produk.length < 40)?_c('div',[_c('p',{staticClass:"title"},[_vm._v(_vm._s(produk.nama_produk))])]):_c('div',[_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(produk.nama_produk.substring(0, 35) + "...")+" ")])])]),_c('h1',[_vm._v(_vm._s(produk.total))]),_c('p',{staticClass:"p-price"},[_vm._v(" Harga di Detail Produk ")]),_c('p',{staticClass:"p-merk"},[_vm._v(" "+_vm._s(produk.jenis_produk)+" "+_vm._s(produk.merk_produk)+" ")])])])])],1)}),0)]),_c('div',{staticClass:"col-xl-3 col-lg-4 col-12 order-2 order-lg-1"},[_c('div',{staticClass:"sidebar"},[_c('a',{attrs:{"href":""}},[_c('h4',{staticClass:"sidebar-title"},[_vm._v(" "+_vm._s(this.$route.params.kategori_produk)+" ")])]),_c('ul',{staticClass:"sidebar-list"},_vm._l((_vm.kategoris),function(kategori){return _c('li',{key:kategori.id_kategori},[_c('a',{attrs:{"href":/product/ +
                    kategori.kategori_produk +
                    ' ' +
                    kategori.tipe_produk +
                    /merk-produk/ +
                    kategori.merk_produk}},[_vm._v(_vm._s(kategori.merk_produk))])])}),0)])])]),(_vm.produks.length)?_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:(_vm.handleScrolledToBottom),expression:"handleScrolledToBottom"}]}):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"/"}},[_vm._v("Home >")])])}]

export { render, staticRenderFns }